import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Link } from "gatsby-plugin-react-i18next";

import { ClassNameProps } from "../../../../common/props";

export const Hero: React.FC<ClassNameProps> = ({ className }: ClassNameProps) => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames(className, "home-hero-bg bg-cover bg-fixed bg-bottom")}
      style={{ height: "calc(100vh - 6rem)" }}
    >
      <div className="h-full bg-blue-1 bg-opacity-75">
        <div className="container mx-auto h-full flex flex-col justify-center p-3">
          <h1 className="leading-tight text-white text-center font-bold font-display text-6xl">
            {t("homePage.hero.heading")}
          </h1>
          <h3 className="text-center text-white font-medium text-2xl">
            {t("homePage.hero.slogan")}
          </h3>
          <div className="mt-5 flex justify-center">
            <Link
              to="/projects"
              className="mr-8 px-6 py-4 bg-orange-3 text-lg font-semibold text-white border-4 rounded-lg transition-all ease-out duration-500 hover:bg-white hover:text-orange-3 flex items-center"
            >
              {t("homePage.hero.projects")}
            </Link>
            <Link
              to="/contact"
              className="px-6 py-4 bg-blue-3 text-lg rounded font-semibold text-white border-4 rounded-lg transition-all ease-out duration-500 hover:bg-white hover:text-blue-3 flex items-center"
            >
              {t("homePage.hero.contact")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
