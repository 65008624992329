import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Link } from "gatsby-plugin-react-i18next";

import { ClassNameProps } from "../../../../common/props";
import { ReactComponent as CostManagementSVG } from "../../../../images/onboarding/cost-managing.svg";
import { ReactComponent as DesigningSVG } from "../../../../images/onboarding/designing.svg";
import { ReactComponent as MonitoringSVG } from "../../../../images/onboarding/monitoring.svg";
import { ReactComponent as PlanningSVG } from "../../../../images/onboarding/planning.svg";
import { ReactComponent as ReportingSVG } from "../../../../images/onboarding/reporting.svg";

type OnboardingCardProps = ClassNameProps & {
  image: React.ReactElement;
  title: string;
  description: string;
};

const OnboardingCard: React.FC<OnboardingCardProps> = ({
  image,
  title,
  description,
  className,
}: OnboardingCardProps) => {
  const { t } = useTranslation();
  return (
    <div className={classNames(className, "p-4 flex")}>
      <div className="shadow-lg rounded-lg flex flex-col p-6 items-center bg-white w-full transition duration-500 transform hover:-translate-y-4 hover:scale-105 hover:shadow-2xl">
        <div className="h-48">
          {React.cloneElement(image, {
            className: "h-full",
          })}
        </div>
        <div className="mt-6 font-semibold text-gray-1 text-xl">{title}</div>
        <div className="mt-3 text-gray-1 text-center">{description}</div>
        <div className="mt-6 flex flex-col h-full justify-end">
          <Link
            to="/projects"
            className="shadow border-2 border-blue-3 font-medium shadow bg-blue-3 px-4 py-2 rounded text-white transition duration-300 hover:bg-white hover:text-blue-3"
          >
            {t("homePage.onboarding.learnMore")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export const Onboarding: React.FC<ClassNameProps> = ({ className }: ClassNameProps) => {
  const { t } = useTranslation();
  return (
    <div className="bg-gray-8">
      <div className={classNames(className, "container mx-auto py-6")}>
        <h2 className="text-blue-3 font-bold font-display text-5xl text-center">{t("homePage.onboarding.heading")}</h2>
        <h3 className="text-center font-medium text-gray-1">{t("homePage.onboarding.subheading")}</h3>
        <div className="mt-6 flex flex-wrap -mx-4">
          <OnboardingCard
            className="w-1/3"
            image={<DesigningSVG/>}
            title={t("homePage.onboarding.designing.title")}
            description={t("homePage.onboarding.designing.description")}
          />
          <OnboardingCard
            className="w-1/3"
            image={<CostManagementSVG/>}
            title={t("homePage.onboarding.costManaging.title")}
            description={t("homePage.onboarding.costManaging.description")}
          />
          <OnboardingCard
            className="w-1/3"
            image={<MonitoringSVG/>}
            title={t("homePage.onboarding.monitoring.title")}
            description={t("homePage.onboarding.monitoring.description")}
          />
          <OnboardingCard
            className="w-1/2"
            image={<ReportingSVG/>}
            title={t("homePage.onboarding.reporting.title")}
            description={t("homePage.onboarding.reporting.description")}
          />
          <OnboardingCard
            className="w-1/2"
            image={<PlanningSVG/>}
            title={t("homePage.onboarding.planning.title")}
            description={t("homePage.onboarding.planning.description")}
          />
        </div>
      </div>
    </div>
  );
};
