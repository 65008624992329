import React from "react";
import { Helmet, useTranslation } from "gatsby-plugin-react-i18next";

import { Hero } from "./Hero";
import { Onboarding } from "./Onboarding";
import "./_home.scss";

import { DefaultLayout } from "../../layouts/DefaultLayout";

export const HomePage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <DefaultLayout>
      <Helmet>
        <title>{t("nav.home")} | {t("company.name")}</title>
      </Helmet>
      <Hero/>
      <Onboarding/>
    </DefaultLayout>
  );
};
